<script setup lang="ts">
const DEFAULT_SIZE = 28
const props = withDefaults(
  defineProps<{
    size?: number
    color?: string
    filled?: boolean
    isGradation?: boolean
    subColor?: string
  }>(),
  {
    size: DEFAULT_SIZE,
    color: '#AFA3A8',
    filled: false,
    isGradation: false,
    subColor: '#FF4673',
  }
)
const fillStr = computed(() => {
  if (props.isGradation) {
    return 'url(#paint0_linear_23957_63607)'
  } else {
    return props.color
  }
})
const scale = computed(() => {
  return props.size / DEFAULT_SIZE
})
const rule = computed(() => (props.filled ? 'nonzero' : 'evenodd'))
</script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    :fill="fillStr"
  >
    <path
      :transform="`scale(${scale})`"
      :fill-rule="rule"
      d="m14.198 19.8 6.203 3.858a.4.4 0 0 0 .599-.347V7a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v16.31a.4.4 0 0 0 .598.348l6.204-3.858a.4.4 0 0 1 .396 0ZM8.7 20.97l4.23-2.63.028-.016a2.1 2.1 0 0 1 2.084 0l.027.016 4.231 2.63V7a.3.3 0 0 0-.3-.3H9a.3.3 0 0 0-.3.3v13.97Z"
      :clip-rule="rule"
    />
    <linearGradient
      v-if="isGradation"
      id="paint0_linear_23957_63607"
      x1="10.3732"
      y1="1.48049"
      x2="-1.98491"
      y2="6.65012"
      gradientUnits="userSpaceOnUse"
    >
      <stop :stop-color="color" />
      <stop offset="1" :stop-color="subColor" />
    </linearGradient>
  </svg>
</template>
